import React from "react";
// import InstructionsList from "../components/InstructionsList/InstructionsList";
import Faq from "../components/SAIRUS_NEW/Faq/Faq";

const InstructionPage = () => {
    return (
        <>
            {/* <InstructionsList /> */}
            <Faq isCabinet={true} />
        </>
    );
};

export default InstructionPage;
