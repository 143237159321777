import H from "@hcaptcha/react-hcaptcha";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface HCaptchaProps {
    setToken: Dispatch<SetStateAction<string>>;
    captchaRef: React.RefObject<H>;
}

const HCaptcha = (props: HCaptchaProps) => {
    const handle = (token: string) => {
        props.setToken(token);
    };

    const [lang, setLang] = useState("en");

    const t = useTranslation()[1].language;

    useEffect(() => {
        switch (t) {
            // case "ru":
            //     setLang("ru");
            //     break;
            case "en":
                setLang("en");
                break;
            case "eo":
                setLang("es");
                break;
            case "cn":
                setLang("zh");
                break;
            case "de":
                setLang("de");
                break;
            case "pt":
                setLang("pt");
                break;
            case "fr":
                setLang("fr");
                break;
        }
    }, [t]);

    return <H ref={props.captchaRef} languageOverride={lang} sitekey="7b5c9d3c-668d-4e09-9d2b-e5a3dd02a7a5" onVerify={handle} />;
};

export default HCaptcha;
