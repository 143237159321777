import React, { useState } from "react";
import styles from "./Faq.module.scss";
import { GlowingIcon } from "../../UI/GlowingIcon/GlowingIcon";

export interface IFaqItem {
    question: string;
    answer: string;
}

const FaqItem: React.FC<IFaqItem> = ({ question, answer }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <div className={[styles.question, open ? styles.open : ""].join(" ")} onClick={() => setOpen(!open)}>
                <span className={styles.questionTitle} dangerouslySetInnerHTML={{ __html: question }}></span>
                <button className={styles.btn}>
                    {open ? (
                        <GlowingIcon name="minus-circle" className={styles.icon} />
                    ) : (
                        <GlowingIcon name="plus-circle" className={styles.icon} />
                    )}
                </button>
            </div>
            <div className={[styles.text, open ? styles.open : ""].join(" ")}>
                <p className={styles.p} dangerouslySetInnerHTML={{ __html: answer }}></p>
            </div>
        </div>
    );
};

export default FaqItem;
