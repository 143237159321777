import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "./reducers/user/user.slice";
import walletReducer from "./reducers/wallets/wallet.slice";
import balanceReducer from "./reducers/balance/balance.slice";
import avatarReducer from "./reducers/avatar/avatar.slice";
import modalReducer from "./reducers/modal/modal.slice";
import chatReducer from "./reducers/chat/chat.slice";
import referralsReducer from "./reducers/referrals/referrals.slice";
import subscribeReducer from "./reducers/subscribe/subscribe.slice";
import errorReducer from "./reducers/error/error.slice";
import newsReducer from "./reducers/news/news.slice";

export const store = configureStore({
    reducer: {
        // user: userReducer,
        wallet: walletReducer,
        balance: balanceReducer,
        avatar: avatarReducer,
        modal: modalReducer,
        chat: chatReducer,
        referrals: referralsReducer,
        subscribe: subscribeReducer,
        error: errorReducer,
        news: newsReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
