// @ts-ignore
import styles from "./AccountMenu.module.scss";
import { AccountMenuItem, AccountMenuProps } from "./AccountMenu.types";
import { NavLink, useLocation } from "react-router-dom";
import { useMenuContext } from "../../contexts/MenuContext";
import { useTranslation } from "react-i18next";
import SocialLinks from "../SAIRUS_NEW/SocialLinks/SocialLinks";

export default function AccountMenu(props: AccountMenuProps) {
    const { open } = useMenuContext();
    const { t } = useTranslation();
    const location = useLocation();

    const items: AccountMenuItem[] = [
        {
            label: t("cabinet.menu.dashboard"),
            icon: "workspace",
            href: "/account",
        },
        {
            label: t("cabinet.menu.deposits"),
            icon: "deposits",
            href: "/account/balance",
        },
        {
            label: t("cabinet.menu.history"),
            icon: "history",
            href: "/account/history",
        },
        {
            label: t("cabinet.menu.team"),
            icon: "team",
            href: "/account/team",
        },
        {
            label: t("cabinet.menu.settings"),
            icon: "settings",
            href: "/account/settings",
        },
        {
            label: t("cabinet.menu.marketing"),
            icon: "marketing",
            href: "/marketing",
        },
        {
            label: t("cabinet.menu.p2p"),
            icon: "p2p",
            href: "/p2p",
        },
        {
            label: t("cabinet.menu.materials"),
            icon: "promo",
            href: "/account/materials",
        },
        {
            label: t("cabinet.menu.instructions"),
            icon: "instructions",
            href: "/account/instructions",
        },
    ];

    return (
        <div className={[styles.accountMenu, open ? styles.open : ""].join(" ")}>
            <div className={styles.menu}>
                {items.map((item) => (
                    <NavLink
                        to={item.href}
                        className={item.href === location.pathname ? `${styles.item} ${styles.active}` : styles.item}
                        key={item.href}
                    >
                        <div className={styles.iconWrapper}>
                            <i className={`icon-${item.icon}`} />
                        </div>
                        <span>{item.label}</span>
                    </NavLink>
                ))}
                <div className={styles.footer}>
                    <SocialLinks className={styles.socials} />
                    <div className={styles.copyright}>
                        <p className="text-xs">Copyright © 2023 SAIRUS LTD. </p>
                        <p className="text-xs">{t("all-right-reserved")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
