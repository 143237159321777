import React from "react";
import LandingHeader from "../components/SAIRUS_NEW/LandingHeader/LandingHeader";
import Container from "../components/SAIRUS_NEW/Container/Container";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <LandingHeader />
            <Container>
                <h2 style={{ fontWeight: 500, marginTop: 50 }}>{t("errors.PAGE_NOT_FOUND")}</h2>
            </Container>
        </>
    );
};

export default ErrorPage;
