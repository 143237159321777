import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../types/User";

interface IReferralsState {
    referrals: IUser[];
    searchReferrals: IUser[];
    teamSize: number;
}

const initialState: IReferralsState = {
    referrals: [] as IUser[],
    searchReferrals: [] as IUser[],
    teamSize: 0,
};

const referralsSlice = createSlice({
    name: "referrals",
    initialState,
    reducers: {
        setReferrals: (state, action: PayloadAction<IUser[]>) => {
            state.referrals = action.payload;
        },
        setSearchReferrals: (state, action: PayloadAction<IUser[]>) => {
            state.searchReferrals = action.payload;
        },
        setTeamSize: (state, action: PayloadAction<number>) => {
            state.teamSize = action.payload;
        },
    },
});

export const { setReferrals, setSearchReferrals, setTeamSize } = referralsSlice.actions;
export default referralsSlice.reducer;
