import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IChatState } from "./chat.types";

const initialState: IChatState = {
    currentChatUser: "",
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setCurrentChatUser: (state, action: PayloadAction<string>) => {
            state.currentChatUser = action.payload;
        },
    },
});

export const { setCurrentChatUser } = chatSlice.actions;

export default chatSlice.reducer;
