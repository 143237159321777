import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// russian ru locale
import translationRU from "./locales/ru.json";
import translationEN from "./locales/en.json";
import translationEO from "./locales/eo.json";
import translationCN from "./locales/cn.json";
import translationDE from "./locales/de.json";
import translationFR from "./locales/fr.json";
import translationPT from "./locales/pt.json";
import translationVN from "./locales/vn.json";

const resources = {
    // russian ru locale
    ru: {
        translation: translationRU,
    },
    en: {
        translation: translationEN,
    },
    eo: {
        translation: translationEO,
    },
    cn: {
        translation: translationCN,
    },
    de: {
        translation: translationDE,
    },
    fr: {
        translation: translationFR,
    },
    pt: {
        translation: translationPT,
    },
    vn: {
        translation: translationVN,
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        // russian ru locale
        fallbackLng: ["en", "ru", "eo", "cn", "de", "fr", "pt", "vn"],
        // fallbackLng: ["en", "eo", "cn", "de", "fr", "pt", "vn"],
        detection: {
            order: ["cookie", "localStorage", "navigator", "htmlTag"],
        },
        // keySeparator: false,
        // interpolation: {
        //   escapeValue: false
        // }
    });

export default i18n;
