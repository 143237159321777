import React from "react";
import styles from "./SectionTitle.module.scss";
import GradientText from "../GradientText/GradientText";

interface ITitle {
    className?: string;
    text: string;
    underlineText?: string;
    gradientText: string;
    withoutSairus?: boolean;
    small?: boolean;
}

const SectionTitle: React.FC<ITitle> = ({ gradientText, className, text, underlineText, withoutSairus, small }) => {
    return (
        <div className={className}>
            <h2 className={[styles.text, small ? styles.small : ""].join(" ")}>{text}</h2>
            {!withoutSairus && <GradientText text={gradientText} underlineText={underlineText} small={small} />}
        </div>
    );
};

export default SectionTitle;
