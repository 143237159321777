// @ts-ignore
import styles from "./Replenish.module.scss";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

export default function UsdtResult(props: any) {
    const { t } = useTranslation();
    const [qrCode, setQrCode] = useState("");
    const [showModal, setShowModal] = useState(false);

    const { paymentWallet, amount } = props.result;

    const fetchQrCode = useCallback(async () => {
        if (!paymentWallet || !amount) return;

        const res = await fetch(`https://api.cryptapi.io/trc20/usdt/qrcode/?address=${paymentWallet}&value=${Number(amount).toFixed(2)}`);

        return res.json();
    }, [paymentWallet, amount]);

    useEffect(() => {
        fetchQrCode()
        .then((res: any) => {
            setQrCode(res.qr_code);
        })
        .catch(e => {
            console.log("Please check the wallet and amount")
        })
        ;
    }, [fetchQrCode]);

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const copyLink = (text: string) => {
        copyText(text);
        setShowModal(true);
        setTimeout(closeModal, 2000);
    };

    return (
        <>
            {/* <div className="h3 text-bold">{t("modals.payment-success")}</div> */}
            <div className={styles.successTitle}>{t("cabinet.make-payment-wallet")}</div>
            <div className={styles.finish} style={{ maxWidth: 420, width: "100%" }} onClick={() => copyLink(paymentWallet)}>
                <div>{paymentWallet}</div>
                <i className="icon icon-copy"></i>
            </div>
            <div className={styles.successTitle}>{t("cabinet.make-payment-amount")}</div>
            <div className={styles.finish} style={{ maxWidth: 200, width: "100%" }} onClick={() => copyLink(amount)}>
                <div>{Number(amount).toFixed(2)} USDT</div>
                <i className="icon icon-copy"></i>
            </div>
            <div className={styles.qr} style={{ marginTop: 40 }}>
                <img src={`data:image/png;base64, ${qrCode}`} alt="" />
            </div>
            <div className={[styles.copy, showModal ? styles.show : ""].join(" ")}>{t("cabinet.copied")}</div>
        </>
    );
}
