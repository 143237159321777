// @ts-ignore
import ReactDOM from "react-dom";
import styles from "./Modal.module.scss";
import { ModalProps } from "./Modal.types";
import { useAppDispatch } from "../../app/hooks";
import { setCurrentModal } from "../../app/reducers/modal/modal.slice";
import ColorCard from "../SAIRUS_NEW/ColorCard/ColorCard";
import { CircleButton } from "../UI/CircleButton";

export default function Modal(props: ModalProps) {
    const { open, closeHandler, title } = props;
    const domNode = document.getElementById("modals") as HTMLElement;

    const dispatch = useAppDispatch();

    const close = closeHandler ? closeHandler : () => dispatch(setCurrentModal(null));

    return ReactDOM.createPortal(
        <div className={styles.wrapper} style={{ display: open ? "flex" : "none", ...props.style }}>
            <div className={styles.backDrop} onClick={close} />
            <ColorCard color="cian" title={title} className={[styles.card, props.className].join(" ")}>
                <CircleButton iconName="plus-circle" dotStyle={{ display: "none" }} className={styles.close} onClick={close} />
                <div className={styles.content}>{props.children}</div>
            </ColorCard>
        </div>,
        domNode
    );
}
