import React from "react";
import Logo from "../Logo/Logo";
import styles from "./AccountHeader.module.scss";
import AccountHeaderMenu from "../AccountHeaderMenu";
import { MenuButton } from "../../UI/MenuButton";
import { MessengerButton } from "../../UI/MessengerButton";
import { useMenuContext } from "../../../contexts/MenuContext";

const AccountHeader = () => {
    const { open, setOpen } = useMenuContext();

    return (
        <header className={styles.accountHeader}>
            <div className={[styles.logoContainer, open ? styles.menuOpen : ""].join(" ")}>
                <Logo className={styles.headerLogo} />
                <div className={styles.menuButtons}>
                    <MessengerButton />
                    <MenuButton
                        open={open}
                        onClick={() => {
                            setOpen((prev) => !prev);
                        }}
                    />
                </div>
            </div>
            <AccountHeaderMenu />
        </header>
    );
};

export default AccountHeader;
