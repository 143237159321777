import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAvatarState } from "./avatar.types";

const initialState: IAvatarState = {
    avatarSrc: "",
};

const balanceSlice = createSlice({
    name: "balance",
    initialState,
    reducers: {
        setAvatarSrc: (state, action: PayloadAction<string>) => {
            state.avatarSrc = action.payload;
        },
    },
});

export const { setAvatarSrc } = balanceSlice.actions;

export default balanceSlice.reducer;
