import { ColProps } from "react-bootstrap/Col";
import { Dispatch, SetStateAction } from "react";
import { ColorsType } from "../../types/Color.type";

import { InputHTMLAttributes } from "react";
import { Mask } from "react-text-mask";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    icon?: any;
    background?: string;
    size?: number;
    mask?: Mask;
    error?: boolean;
    textfieldContainerStyle?: React.CSSProperties;
}

interface AuthInput {
    input: InputProps;
    col: ColProps;
}

interface AuthLink {
    text: string;
    href: string;
    handler?: () => void;
}
export interface AuthFormProps {
    color: ColorsType;
    title: string;
    inputs: AuthInput[];
    handler?: () => void;
    button?: JSX.Element;
    captcha: false | JSX.Element;
    links: AuthLink[];
    accept?: [boolean, Dispatch<SetStateAction<boolean>>];
    acceptError?: boolean;
    text?: string;
    errorCount?: number;
    buttonLabel: string;
    error?: LightingErrors;
}

export enum LightingErrors {
    EMPTY,
    EMPTY_LOGIN,
    EMPTY_EMAIL,
    EMPTY_PASSWORD,
    EMPTY_MESSAGE,
    PASSWORDS_MISMATCH,
    INVALID_USER_PASSWORD,
    NOT_ACTIVATED_ERROR,
    CAPTCHA_ERROR,
    INVALID_CAPTCHA,
    ACCEPT_ERROR,
    PASSWORD_NOT_MATCH_PATTERN,
    LOGIN_NOT_MATCH_PATTERN,
    EMAIL_NOT_MATCH_PATTERN,
    USER_NOT_FOUND,
}
