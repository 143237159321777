import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IWallet, IWalletState } from "./wallet.types";
// import { fetchUser, handleLogin } from "../user/user.thunk";
// import { IUser } from "../user/user.types";
// import { addWallet, deleteWallet, updateWallet } from "./wallet.thunk";

const initialState: IWalletState = {
    wallets: [],
    currentWallet: {} as IWallet,
    isLoading: false,
    error: null,
};

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setCurrentWallet: (state, action: PayloadAction<IWallet>) => {
            state.currentWallet = action.payload;
            state.error = null;
        },
        changeWalletNumber: (state, action) => {
            state.currentWallet.wallet = action.payload;
        },
        setWallets: (state, action: PayloadAction<IWallet[]>) => {
            state.wallets = action.payload;
        },
    },
});

export const { setCurrentWallet, changeWalletNumber, setWallets } = walletSlice.actions;

export default walletSlice.reducer;
