import { FC } from "react";
import { CheckboxProps } from ".";
import styles from "./Checkbox.module.scss";

export const Checkbox: FC<CheckboxProps> = (props) => {
    return (
        <div className={styles.checkbox}>
            <input onChange={props.onChange} name={props.name} id={props.name} className={styles.checkbox__input} type="checkbox" />
            <label htmlFor={props.name} className={styles.checkbox__label}>
                {props.title}
            </label>
        </div>
    );
};
