import { FC } from "react";
import { LockButtonProps } from ".";
import { CircleButton } from "../CircleButton";
import styles from "./LockButton.module.scss";
import { useApiContext } from "../../../contexts/ApiContext";

export const LockButton: FC<LockButtonProps> = (props) => {
    const { isAuth } = useApiContext();
    return (
        <div className={[styles.lockButton, props.className].join(" ")}>
            <div className={styles.lockButton__star}></div>
            <CircleButton
                onClick={props.onClick}
                iconName={isAuth ? "unlock" : "lock"}
                style={{
                    boxShadow: "0 0 5px 5px #df4bff",
                }}
                iconStyle={{
                    backgroundColor: "#df4bff",
                    width: isAuth ? 20 : "",
                }}
                dotStyle={{
                    backgroundColor: "#df4bff",
                }}
            />
        </div>
    );
};
