import { FC } from "react";
import { MenuButtonProps } from ".";
import { CircleButton } from "../CircleButton";
import styles from "./MenuButton.module.scss";

export const MenuButton: FC<MenuButtonProps> = ({ className, onClick, open = false }) => {
    return (
        <div className={[styles.addUserButton, className].join(" ")}>
            <div className={styles.addUserButton__star}></div>
            <CircleButton
                onClick={onClick}
                iconName={open ? "close" : "menu"}
                style={{
                    boxShadow: "0 0 5px 5px #8700fd",
                }}
                iconStyle={{
                    backgroundColor: "#8700fd",
                }}
                dotStyle={{
                    backgroundColor: "#8700fd",
                }}
            />
        </div>
    );
};
