import FlagRu from "../../assets/img/svg/flag_ru.svg";
import FlagEn from "../../assets/img/svg/flag_en.svg";
import FlagCn from "../../assets/img/svg/flag_cn.svg";
import FlagVn from "../../assets/img/svg/flag_vn.svg";
// import FlagPt from "../../assets/img/svg/flag_pt.svg";
import FlagDe from "../../assets/img/svg/flag_de.svg";
import FlagEo from "../../assets/img/svg/flag_eo.svg";
import FlagFr from "../../assets/img/svg/flag_fr.svg";
import FlagPt from "../../assets/img/svg/flag_pt.svg";
import icon2 from "../../assets/img/svg/mark-mini.svg";

import styles from "./LanguageSwitcher.module.scss";
import { useEffect, useState } from "react";
import { ILang, LanguageSwitcherProps } from "./LanguageSwitcher.types";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../../hooks/useMediaQuery";

const languages: ILang[] = [
    {
        icon: FlagEn,
        label: "EN",
        code: "en",
    },
    {
        icon: FlagEo,
        label: "EO",
        code: "eo",
    },
    {
        icon: FlagCn,
        label: "中文",
        code: "cn",
    },
    // russian ru locale
    {
        icon: FlagRu,
        label: "RU",
        code: "ru",
    },
    {
        icon: FlagDe,
        label: "DE",
        code: "de",
    },
    {
        icon: FlagPt,
        label: "PT",
        code: "pt",
    },
    {
        icon: FlagFr,
        label: "FR",
        code: "fr",
    },
    {
        icon: FlagVn,
        label: "VN",
        code: "vn",
    },
];
export default function LanguageSwitcher(props: LanguageSwitcherProps) {
    const [lng, setLng] = useState<ILang>(languages[0]);
    const [open, setOpen] = useState(false);
    const { i18n } = useTranslation();

    useEffect(() => {
        const cur = languages.find((item) => item.code === i18n.language);
        if (cur) setLng(cur);
    }, [i18n.language]);

    const match = useMediaQuery("(min-width: 450px)");

    const handler = (lang: ILang) => {
        i18n.changeLanguage(lang.code);
    };

    return (
        <div
            className={[styles.switcher, props.className, open ? styles.open : ""].join(" ")}
            onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
            }}
        >
            <img src={lng.icon} alt={lng.label} className={styles.flag} />
            <span>{lng.label}</span>
            {match && <img src={icon2} alt={lng.label} />}

            <div className={[styles.list, props.top ? styles.top : "", open ? styles.open : ""].join(" ")}>
                {languages.map((item, index) => (
                    <div className={styles.item} onClick={() => handler(item)} key={index}>
                        <img src={item.icon} alt={item.label} className={styles.flag} />
                        <span>{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
