import { createContext, Dispatch, SetStateAction, useContext } from "react";

export interface IMenuContext {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const MenuContext = createContext<IMenuContext>({} as IMenuContext);

export const useMenuContext = () => useContext(MenuContext);

export default MenuContext;
