// @ts-ignore
import styles from "./Wallets.module.scss";
import { AddProps, WalletProps, WalletsProps } from "./Wallets.types";
import plusIcon from "../../assets/plus.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCurrentWallet } from "../../app/reducers/wallets/wallet.slice";
import { setCurrentModal } from "../../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../../app/reducers/modal/modal.types";
import { GlowingIcon } from "../UI/GlowingIcon";

export default function Wallets(props: WalletsProps) {
    const { t } = useTranslation();

    const { wallets } = useAppSelector((state) => state.wallet);

    const dispatch = useAppDispatch();

    return (
        <div className={styles.wallets}>
            <div className={styles.star}></div>
            <div className={styles.title}>
                <span>{t("cabinet.my-wallets")}</span>
                <GlowingIcon name="wallets" className={styles.icon} />
            </div>
            {!wallets || wallets.length === 0 ? (
                <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            className={styles.wallet}
                            onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.ADD))}
                            style={{ padding: "25px" }}
                        >
                            <div className={styles.plus}>
                                <img src={plusIcon} alt="+" />
                            </div>
                        </div>
                    </div>
                    <p className={styles.first}>{t("cabinet.first-wallet")}</p>
                </>
            ) : (
                <div className={styles.list}>
                    {wallets?.map((wallet) => (
                        <Wallet
                            wallet={wallet}
                            key={wallet.wallet}
                            isSettings={true}
                            onClick={() => {
                                dispatch(setCurrentWallet(wallet));
                                dispatch(setCurrentModal(ModalsTypesEnum.UPDATE));
                            }}
                        />
                    ))}

                    {wallets?.length < 1 && <Add onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.ADD))} />}
                </div>
            )}
        </div>
    );
}

export function Wallet(props: WalletProps) {
    const { t } = useTranslation();

    return (
        <div
            className={[
                styles.wallet,
                props.isSettings ? styles.isSettings : "",
                props.disabled ? styles.disabled : "",
                styles[props.wallet.paymentSystem || ""],
            ].join(" ")}
            onClick={props?.onClick}
            style={{ ...props.style, display: props.wallet.paymentSystem !== "USDT" ? "none" : "" }}
        >
            <span className={styles.walletIcon}></span>
            <span className={styles.walletLabel}>{t(`wallets.${props.wallet.paymentSystem}`)}</span>
            <GlowingIcon name="arrow-right" className={styles.icon} />
        </div>
    );
}

export function Add(props: AddProps) {
    const handler = () => {
        if (props.onClick) props.onClick();
    };

    return (
        <>
            <div className={styles.wallet} onClick={handler} style={props.color ? { backgroundColor: props.color } : {}}>
                <div className={styles.plus}>
                    <i className="icon icon-plus-circle"></i>
                    {/* <img src={plusIcon} alt="+" /> */}
                </div>
            </div>
        </>
    );
}
