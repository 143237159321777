import AuthWrapper from "../components/AuthWrapper";
import AuthForm from "../components/AuthForm/AuthForm";
import { useTranslation } from "react-i18next";
import { useApiContext } from "../contexts/ApiContext";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import H from "@hcaptcha/react-hcaptcha";
import HCaptcha from "../components/HCaptcha/HCaptcha";
import { useAppDispatch } from "../app/hooks";
import { LightingErrors } from "../components/AuthForm/AuthForm.types";
import { setError } from "../app/reducers/error/error.slice";
import { setCurrentModal } from "../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../app/reducers/modal/modal.types";

export default function ActivationPage() {
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [lightingError, setLightingError] = useState<LightingErrors>(LightingErrors.EMPTY);
    const { t } = useTranslation();
    const { response } = useApiContext();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const captchaRef = useRef<H>(null);

    const send = () => {
        if (!email) {
            setLightingError(LightingErrors.EMPTY_LOGIN);
            dispatch(setError("EMPTY_EMAIL"));
            return;
        }
        if (!token) {
            dispatch(setError("CAPTCHA_ERROR"));
            return;
        }
        response(`/activate/resend?email=${email}&token=${token}`).then((res) => {
            if (res.code === "OK") {
                setEmail("");
                dispatch(setCurrentModal(ModalsTypesEnum.SUCCESSFULL_SEND_ACTIVATE_LINK));
                navigate("/login");
            } else {
                setLightingError(LightingErrors[res.message] as unknown as LightingErrors);
                dispatch(setError(res.message));
            }
            setToken("");
            captchaRef.current?.resetCaptcha();
        });
    };

    const changeData = (e: React.ChangeEvent<HTMLInputElement>, callback: (s: string) => void) => {
        setLightingError(LightingErrors.EMPTY);
        callback(e.target.value);
    };

    return (
        <>
            <AuthWrapper title={t("auth.reset-text")}>
                <AuthForm
                    buttonLabel={t("auth.reset-button")}
                    color="cian"
                    title={t("auth.resend-title")}
                    text={t("auth.resend-description") || undefined}
                    inputs={[
                        {
                            col: { md: 12 },
                            input: {
                                label: t("auth.email") || undefined,
                                type: "text",
                                icon: "",
                                value: email,
                                error: lightingError === LightingErrors.EMPTY_LOGIN || lightingError === LightingErrors.USER_NOT_FOUND,
                                onChange: (e) => changeData(e, setEmail),
                                textfieldContainerStyle: { marginBottom: 20 },
                            },
                        },
                    ]}
                    links={[]}
                    // error={error}
                    captcha={<HCaptcha captchaRef={captchaRef} setToken={setToken} />}
                    handler={send}
                />
            </AuthWrapper>
        </>
    );
}
