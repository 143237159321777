// @ts-ignore
import styles from "./Replenish.module.scss";
import { PMForm, PayeerForm, ReplenishProps } from "./Replenish.types";
import React, { useEffect, useRef, useState } from "react";
import { Add, Wallet } from "../Wallets/Wallets";
import Button from "../Button";
import { useLocation } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../contexts/ApiContext";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCurrentModal, setModalStep } from "../../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../../app/reducers/modal/modal.types";
import { setCurrentWallet } from "../../app/reducers/wallets/wallet.slice";
import PerfectMoneyResult from "./PerfectMoneyResult";
import UsdtResult from "./UsdtResult";
import ModalStepTitle from "../UI/ModalStepTitle/ModalStepTitle";
import { TextField } from "../UI/TextField";
import { setError } from "../../app/reducers/error/error.slice";

const MIN_TOP_UP_VALUE = 10;
const HOST = window.location.origin;

export default function Replenish(props: ReplenishProps) {
    const [value, setValue] = useState(props.sum || MIN_TOP_UP_VALUE);
    const [usdtData, setUsdtData] = useState({});
    const [isLower, setIsLower] = useState(false);
    // const [error, setError] = useState("");
    const isPhone = useMediaQuery("(max-width: 767.98px)");
    const [PMformData, setPMFormData] = useState<PMForm>({} as PMForm);
    const [payeerFormData, setPayeerFormData] = useState<PayeerForm>({} as PayeerForm);
    const PerfectMoneyForm = useRef<HTMLFormElement>(null);
    const payeerForm = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();
    const { response } = useApiContext();

    const {
        wallet: { wallets, currentWallet },
        modal: { modalStep: step },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const location = useLocation();

    const normalizeLocationPath = () => {
        return location.pathname.match(/[a-zA-Z\-_]+/g)?.join("/");
    };

    const perfectMoneyHandler = () => {
        if (PerfectMoneyForm.current) {
            response(
                "/transaction/get_add_funds_requisites",
                "POST",
                JSON.stringify({
                    amount: value,
                    currency: currentWallet.currency,
                    paymentSystem: currentWallet.paymentSystem,
                })
            ).then((r) => {
                if (r.code === "OK" && r.result) {
                    setPMFormData(r.result);
                } else {
                    if (r.error) dispatch(setError(r.error.message));
                    if (r.message) dispatch(setError(r.message));
                }
            });
        }
    };

    const usdtHandler = () => {
        response(
            "/transaction/get_add_funds_requisites",
            "POST",
            JSON.stringify({
                amount: value,
                currency: currentWallet.currency,
                paymentSystem: currentWallet.paymentSystem,
            })
        ).then((r) => {
            if (r.code === "OK" && r.result) {
                dispatch(setModalStep(3));
                setUsdtData(r.result);
            } else {
                if (r.error) dispatch(setError(r.error.message));
                if (r.message) dispatch(setError(r.messag));
            }
        });
    };

    const payeerHandler = () => {
        if (payeerForm.current) {
            response(
                "/transaction/get_add_funds_requisites",
                "POST",
                JSON.stringify({
                    amount: value,
                    currency: currentWallet.currency,
                    paymentSystem: currentWallet.paymentSystem,
                })
            ).then((r) => {
                if (r.code === "OK" && r.result) {
                    setPayeerFormData(r.result);
                } else {
                    if (r.error) dispatch(setError(r.error.message));
                    if (r.message) dispatch(setError(r.message));
                }
            });
        }
    };

    const send = () => {
        if (isLower) return dispatch(setError("TOP_UP_LOVER_THAN_MIN"));

        localStorage.setItem("PAYMENT_SYSTEM", currentWallet.paymentSystem || "");

        switch (currentWallet.paymentSystem) {
            case "PAYEER":
                payeerHandler();
                break;
            case "PERFECT_MONEY":
                perfectMoneyHandler();
                break;
            case "USDT":
                usdtHandler();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (PerfectMoneyForm.current && PMformData.amount) {
            localStorage.setItem("PAYMENT_AMOUNT", String(PMformData.amount));
            PerfectMoneyForm.current.submit();
        }
    }, [PMformData]);

    useEffect(() => {
        if (payeerForm.current && payeerFormData.amount) {
            localStorage.setItem("PAYMENT_AMOUNT", String(payeerFormData.amount));
            payeerForm.current.submit();
        }
    }, [payeerFormData]);

    useEffect(() => {
        if (value < MIN_TOP_UP_VALUE) setIsLower(true);
        else setIsLower(false);
    }, [value]);

    useEffect(() => {
        if (step === 1) {
            setValue(10);
        }
    }, [step]);

    return (
        <div className={styles.replenish}>
            {step !== 3 && (
                <div className={`h3 text-bold ${!isPhone ? "text-center" : "text-left"} ${styles.backWrapper}`}>
                    {step !== 1 && (
                        <span className={[styles.link, styles.back].join(" ")} onClick={() => dispatch(setModalStep(1))}>
                            <i>←</i> <b>{t("modals.back")}</b>
                        </span>
                    )}
                </div>
            )}
            {step === 1 && (
                <>
                    <ModalStepTitle step={1} text={t("modals.select-ps")} />

                    <div className={styles.text}>
                        {t("modals.minimal-sum")} {MIN_TOP_UP_VALUE} {t("currency")}
                    </div>
                    <div className={styles.list}>
                        {wallets.map((wallet) => (
                            <Wallet
                                color={"#A6A6A6"}
                                onClick={() => {
                                    dispatch(setCurrentWallet(wallet));
                                    dispatch(setModalStep(2));
                                }}
                                wallet={wallet}
                                key={wallet.paymentSystem}
                            />
                        ))}
                        {wallets.length < 1 && <Add onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.ADD))} />}
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <ModalStepTitle step={2} text={t("modals.zapolnite-formu")} />

                    <div className={styles.text}>
                        {t("modals.minimal-sum")} {MIN_TOP_UP_VALUE} {t("currency")}
                    </div>

                    <div className={[styles.flexbox, styles.amount].join(" ")}>
                        <div>
                            <div className={styles.amountText}>{t("modals.amount-of-investment")}</div>
                            <TextField
                                value={value}
                                type="number"
                                fullWidth={true}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e.target.value))}
                            >
                                {t("currency")}
                            </TextField>
                        </div>
                        <Button label={t("modals.create-payment")} onClick={send} isNeededStar={false} />
                    </div>

                    <div className={styles.notice}>
                        <div>
                            <div>{t("modals.internal-rate")}</div>
                            <div>{`1 ${currentWallet.currency} = 1 ${t("currency")}`}</div>
                        </div>
                        <div>
                            <div>{t("modals.need-to-pay")}</div>
                            <div>
                                {value} {currentWallet.currency}
                            </div>
                        </div>
                    </div>
                    <form action="https://perfectmoney.com/api/step1.asp" method="POST" ref={PerfectMoneyForm}>
                        <input type="hidden" name="PAYEE_ACCOUNT" value={PMformData.payeeAccount} />
                        <input type="hidden" name="PAYEE_NAME" value={PMformData.payeeName} />
                        <input type="hidden" name="PAYMENT_ID" value={PMformData.paymentId} />
                        <input type="hidden" name="PAYMENT_AMOUNT" value={Number(PMformData.amount)} />
                        <input type="hidden" name="PAYMENT_UNITS" value={PMformData.currency} />
                        <input type="hidden" name="STATUS_URL" value={PMformData.statusUrl} />
                        <input
                            type="hidden"
                            name="PAYMENT_URL"
                            value={`${HOST}/payment/success/${normalizeLocationPath()}` || PMformData.paymentSuccessUrl}
                        />
                        <input type="hidden" name="PAYMENT_URL_METHOD" value={PMformData.paymentSuccessUrlMethod} />
                        <input
                            type="hidden"
                            name="NOPAYMENT_URL"
                            value={`${HOST}/payment/failed/${normalizeLocationPath()}` || PMformData.paymentFailedUrl}
                        />
                        <input type="hidden" name="NOPAYMENT_URL_METHOD" value={PMformData.paymentFailedUrlMethod} />
                    </form>
                    <form action={payeerFormData.redirectUrl} method="POST" ref={payeerForm}>
                        <input type="hidden" name="m_shop" value={payeerFormData.sciShop} />
                        <input type="hidden" name="m_orderid" value={payeerFormData.paymentId} />
                        <input type="hidden" name="m_amount" value={payeerFormData.amount} />
                        <input type="hidden" name="m_curr" value={payeerFormData.currency} />
                        <input type="hidden" name="m_desc" value={payeerFormData.desc} />
                        <input type="hidden" name="m_sign" value={payeerFormData.sciSign} />
                    </form>
                </>
            )}
            {step === 3 && localStorage.getItem("PAYMENT_SYSTEM") === "PERFECT_MONEY" && <PerfectMoneyResult />}
            {step === 3 && localStorage.getItem("PAYMENT_SYSTEM") === "PAYEER" && <PerfectMoneyResult />}
            {step === 3 && localStorage.getItem("PAYMENT_SYSTEM") === "USDT" && <UsdtResult result={usdtData} />}
        </div>
    );
}
