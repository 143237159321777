// @ts-ignore
import styles from "./Transfer.module.scss";
import { TransferProps } from "./Transfer.types";
import { useEffect, useState } from "react";
import { IUser } from "../../types/User";
import Button from "../Button";
import useLocalUser from "../../hooks/useLocalUser";
import { useApiContext } from "../../contexts/ApiContext";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setBalance } from "../../app/reducers/balance/balance.slice";
import { setCurrentModal, setModalStep } from "../../app/reducers/modal/modal.slice";
import ModalStepTitle from "../UI/ModalStepTitle/ModalStepTitle";
import { TextField } from "../UI/TextField";
import { setError } from "../../app/reducers/error/error.slice";
import { Link, useLocation } from "react-router-dom";

const MINIMUM_VALUE = 1;

export default function Transfer(props: TransferProps) {
    // const [referrals, setReferrals] = useState<IUser[]>([]);
    const [login, setLogin] = useState("");
    const [user, setUser] = useState<IUser | undefined>(props.user);
    const [value, setValue] = useState(1);
    const [lastId, setLastId] = useState("");
    // const [error, setError] = useState("");
    const [pinCode, setPinCode] = useState("");
    const { userName } = useLocalUser(user);
    const { response, reload, isAuth } = useApiContext();
    const isPhone = useMediaQuery("(max-width: 767.98px)");
    const { t } = useTranslation();

    const location = useLocation();

    const { balance } = useAppSelector((state) => state.balance);

    const {
        modal: { modalStep: step, currentModal },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    // const dispatch(setError = (error: string) => {
    //     setError(error);
    //     setTimeout(() => //setError(""), 3000);
    // };

    useEffect(() => {
        if (!isAuth) return;
        if (user) dispatch(setModalStep(2));
        else return;
        // response("/referrals").then((res) => {
        //     if (res.code === "OK" && Array.isArray(res.result.referrals)) {
        //         setReferrals(res.result.referrals as IUser[]);
        //     }
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    useEffect(() => {
        //setError("");
        if (step === 1) {
            setValue(1);
            setLogin("");
            setPinCode("");
        }
    }, [step, currentModal]);

    const noUserHandler = async () => {
        response("/user/short?login=" + login).then((res) => {
            if (res.result) {
                setUser(res.result);
                //setError("");
                dispatch(setModalStep(2));
            } else {
                dispatch(setError("USER_NOT_FOUND_BY_LOGIN"));
            }
        });
    };

    const sendHandler = () => {
        if (value < MINIMUM_VALUE) {
            dispatch(setError("TRANSFER_LOVER_THAN_MIN"));
            return;
        }
        response(`/transfer?login=${user?.login}&amount=${value}`, "POST").then((res) => {
            if (res.code === "OK")
                response("/transfer").then((res) => {
                    //setError("");
                    dispatch(setModalStep(3));
                    setLastId(res.result[res.result.length - 1].id);
                });
            else dispatch(setError(res.message));
        });
    };

    const acceptHandler = () => {
        response(`/transfer/confirm?transferId=${lastId}&pinCode=${pinCode}`, "POST").then((res) => {
            if (res.code === "OK") {
                //setError("");
                dispatch(setBalance(balance - value));
                dispatch(setModalStep(4));
                reload();
            } else {
                dispatch(setError(res.message));
            }
        });
    };

    return (
        <div className={styles.transfer}>
            {step !== 4 && (
                <div className={`h3 text-bold ${!isPhone ? "text-center" : "text-left"} ${styles.backWrapper}`}>
                    {step !== 1 && (
                        <span className={[styles.link, styles.back].join(" ")} onClick={() => dispatch(setModalStep(1))}>
                            <i>←</i> <b>{t("modals.back")}</b>
                        </span>
                    )}
                </div>
            )}
            {step === 1 && (
                <>
                    <ModalStepTitle step={1} text={t("modals.transfer-partner-login")} />

                    <TextField
                        className={styles.textField}
                        value={login}
                        fullWidth={true}
                        placeholder={t("modals.transfer-partner-login-placeholder") || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            //setError("");
                            setLogin(e.target.value);
                        }}
                    >
                        <i className={`icon icon-user`} />
                    </TextField>

                    {/* <div className={["error", styles.error, error ? styles.show : ""].join(" ")}>{error && t(`errors.${error}`)}</div> */}

                    <Button label={t("modals.send")} onClick={noUserHandler} isNeededStar={false} />
                </>
            )}
            {step === 2 && (
                <>
                    <ModalStepTitle step={2} text={t("modals.zapolnite-formu-dlya-perevoda")} />

                    <div className={styles.user}>
                        <div className={styles.text}>{t("modals.selected-partner")}</div>
                        <div className={styles.name}>
                            <div className={styles.iconWrapper}>
                                <i className={`icon-user`} />
                            </div>
                            <div>{userName}</div>
                        </div>
                        <div className={styles.email}>
                            <div className={styles.iconWrapper}>
                                <i className={`icon-team-email`} />
                            </div>
                            <div>{user?.email}</div>
                        </div>
                        <div className={styles.change} onClick={() => dispatch(setModalStep(1))}>
                            {t("modals.change")}
                        </div>
                    </div>
                    {/* <div className={["error", styles.error, error ? styles.show : ""].join(" ")}>{error && t(`errors.${error}`)}</div> */}

                    <div className={[styles.flexbox, styles.amount].join(" ")}>
                        <div>
                            <div className={styles.amountText}>{t("modals.transfer-amount")}</div>
                            <TextField
                                value={value}
                                type="number"
                                fullWidth={true}
                                min={1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e.target.value))}
                            >
                                {t("currency")}
                            </TextField>
                        </div>
                        <Button label={t("modals.send")} onClick={sendHandler} isNeededStar={false} />
                    </div>
                </>
            )}
            {step === 3 && (
                <>
                    <ModalStepTitle step={3} text={t("modals.final-transaction")} />
                    <TextField
                        className={styles.textField}
                        value={pinCode}
                        type="number"
                        placeholder={t("modals.pincode") || ""}
                        fullWidth={true}
                        min={1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPinCode(e.target.value)}
                    />
                    <Button label={t("modals.send")} onClick={acceptHandler} isNeededStar={false} />
                </>
            )}
            {step === 4 && (
                <>
                    <div className={styles.successTitle}>{t("modals.transfer-success")}</div>
                    <div className={styles.successText}>
                        {t("modals.transfer-result.before")} <br />
                        <strong>{userName}</strong>
                        {t("modals.transfer-result.after")}{" "}
                    </div>

                    {!location.pathname.includes("/account/history") && (
                        <>
                            <Link
                                to={"/account/history?type=TRANSFER"}
                                className={[styles.link, styles.history].join(" ")}
                                onClick={() => dispatch(setCurrentModal(null))}
                            >
                                <b>{t("cabinet.operations-history-link")}</b> <i>→</i>
                            </Link>
                        </>
                    )}
                    <div className={styles.finish}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: String(t("modals.you-transferred", { 0: String(value), 1: userName, 2: t("currency") })),
                            }}
                        ></div>
                    </div>
                </>
            )}
        </div>
    );
}
