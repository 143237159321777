import { FC } from "react";
import { AddUserButtonProps } from ".";
import { CircleButton } from "../CircleButton";
import styles from "./AddUserButton.module.scss";

export const AddUserButton: FC<AddUserButtonProps> = (props) => {
    return (
        <div className={[styles.addUserButton, props.className].join(" ")}>
            <div className={styles.addUserButton__star}></div>
            <CircleButton
                onClick={props.onClick}
                iconName="add-user"
                style={{
                    boxShadow: "0 0 5px 5px #0066ff",
                }}
                iconStyle={{
                    backgroundColor: "#0066ff",
                }}
                dotStyle={{
                    backgroundColor: "#0066ff",
                }}
            />
        </div>
    );
};
