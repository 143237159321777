// @ts-ignore
import styles from "./RemoveWallet.module.scss";
import { RemoveWalletProps } from "./RemoveWallet.types";
import Button from "../Button";
import { Link, useLocation } from "react-router-dom";
import { useApiContext } from "../../contexts/ApiContext";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setWallets } from "../../app/reducers/wallets/wallet.slice";
import { setModalStep } from "../../app/reducers/modal/modal.slice";
import { Wallet } from "../Wallets/Wallets";
import { TextField } from "../UI/TextField";
import { setError } from "../../app/reducers/error/error.slice";

export default function RemoveWallet(props: RemoveWalletProps) {
    const { response } = useApiContext();
    const { t } = useTranslation();

    const location = useLocation();

    const {
        wallet: { currentWallet: wallet },
        modal: { modalStep: step },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const handler = () => {
        response(`/user/wallet?paymentSystem=${wallet.paymentSystem}&currency=${wallet.currency}`, "DELETE").then((res) => {
            if (res.code === "OK") {
                if (res.result) dispatch(setWallets(res.result));
                else dispatch(setWallets([]));
                dispatch(setModalStep(2));
            } else dispatch(setError(res.error.message));
        });
    };

    return (
        <div className={styles.removeWallet}>
            {step === 1 && (
                <>
                    <Wallet wallet={wallet} />
                    <div className={styles.title}>{t("modals.delete-wallet-title", [t(`wallets.${wallet.paymentSystem}`)])}</div>
                    <div className={styles.text}>{t("modals.delete-wallet-warning")}</div>
                    <div className={styles.text}>{t("modals.delete-wallet-address")}</div>
                    <TextField
                        className={styles.textField}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => null}
                        value={wallet.wallet}
                    />
                    <Button label={t("modals.confirm-deletion")} className={styles.button} onClick={handler} isNeededStar={false} />
                </>
            )}
            {step === 2 && (
                <>
                    <Wallet wallet={wallet} />
                    <div className={styles.title}>{t("modals.delete-wallet-success")}</div>
                    {!location.pathname.includes("/account/settings") && (
                        <>
                            <div style={{ marginTop: "15px" }}>{t("modals.delete-wallet-new-link")}</div>
                            <Link to={"/account/settings/"} className="text-green text-underline">
                                {t("cabinet.account-settings")}
                            </Link>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
