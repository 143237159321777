// @ts-ignore
import styles from "./Withdraw.module.scss";
import { WithdrawProps } from "./Withdraw.types";
import { useEffect, useState } from "react";
import { Add, Wallet } from "../Wallets/Wallets";
import Button from "../Button";
import { Link, useLocation } from "react-router-dom";
import { useApiContext } from "../../contexts/ApiContext";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCurrentModal, setModalStep } from "../../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../../app/reducers/modal/modal.types";
import { setCurrentWallet } from "../../app/reducers/wallets/wallet.slice";
import { setBalance } from "../../app/reducers/balance/balance.slice";
import ModalStepTitle from "../UI/ModalStepTitle/ModalStepTitle";
import { TextField } from "../UI/TextField";
import { setError } from "../../app/reducers/error/error.slice";

export default function Withdraw(props: WithdrawProps) {
    const [value, setValue] = useState<number>(10);
    // const [error, setError] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const isPhone = useMediaQuery("(max-width: 767.98px)");
    const { t } = useTranslation();
    const { response } = useApiContext();

    const location = useLocation();

    const {
        balance: { balance },
        wallet: { wallets, currentWallet: wallet },
        modal: { modalStep: step, currentModal },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (step === 1) {
            setValue(10);
            // setError("");
            setPinCode("");
        }
    }, [step, currentModal]);

    // const dispatch(setError = (error: string) => {
    //     setError(error);
    //     setTimeout(() => setError(""), 3000);
    // };

    const send = () => {
        if (value < 10) {
            return dispatch(setError("WITHDRAW_LOVER_THAN_MIN"));
        }
        const payload = {
            amount: value,
            currency: wallet.currency,
            paymentSystem: wallet.paymentSystem,
        };
        response("/transaction/withdraw_funds", "POST", JSON.stringify(payload)).then((res) => {
            if (res.code === "OK") {
                setTransactionId(res.result.id);
                // setError("");
                dispatch(setModalStep(3));
            } else {
                if (res.message) dispatch(setError(res.message));
                if (res.error) dispatch(setError(res.error.message));
            }
        });
    };

    const acceptHandler = () => {
        if (!pinCode) {
            return dispatch(setError("TRANSFER_WRONG_PIN_CODE"));
        }

        const data = JSON.stringify({
            pinCode,
            transactionId,
        });
        response(`/transaction/withdraw_funds/confirm`, "POST", data).then((res) => {
            if (res.code === "OK") {
                // setError("");
                dispatch(setBalance(balance - value));
                dispatch(setModalStep(4));
            } else {
                dispatch(setError(res.message));
            }
        });
    };

    return (
        <div className={styles.withdraw}>
            {step !== 4 && (
                <div className={`h3 text-bold ${!isPhone ? "text-center" : "text-left"} ${styles.backWrapper}`}>
                    {step !== 1 && (
                        <span className={[styles.link, styles.back].join(" ")} onClick={() => dispatch(setModalStep(1))}>
                            <i>←</i> <b>{t("modals.back")}</b>
                        </span>
                    )}
                </div>
            )}
            {step === 1 && (
                <>
                    <ModalStepTitle step={1} text={t("modals.select-wallet")} />
                    <div className={styles.list}>
                        {wallets.map((wallet) => (
                            <Wallet
                                color={"#A6A6A6"}
                                onClick={() => {
                                    dispatch(setCurrentWallet(wallet));
                                    dispatch(setModalStep(2));
                                }}
                                wallet={wallet}
                                key={wallet.paymentSystem}
                            />
                        ))}
                        {wallets.length < 1 && <Add onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.ADD))} />}
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <ModalStepTitle step={2} text={t("modals.withdrawal-form")} />
                    <div className={styles.text}>{t("modals.withdrawal-wallet")}</div>
                    <TextField className={styles.textField} value={wallet?.wallet} fullWidth={true} />
                    {/* <div className={["error", styles.error, error ? styles.show : ""].join(" ")}>{error && t(`errors.${error}`)}</div> */}

                    <div className={[styles.flexbox, styles.amount].join(" ")}>
                        <div>
                            <div className={styles.amountText}>{t("modals.amount-of-investment")}</div>
                            <TextField
                                value={value}
                                type="number"
                                fullWidth={true}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e.target.value))}
                            >
                                {t("currency")}
                            </TextField>
                        </div>
                        <Button label={t("modals.sozdat-zayavku")} onClick={send} isNeededStar={false} />
                    </div>
                </>
            )}
            {step === 3 && (
                <>
                    <ModalStepTitle step={3} text={t("modals.final-transaction")} />

                    <TextField
                        className={styles.textField}
                        value={pinCode}
                        fullWidth={true}
                        placeholder={t("modals.pincode") || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPinCode(e.target.value)}
                    />

                    {/* <div className={["error", styles.error, error ? styles.show : ""].join(" ")}>{error && t(`errors.${error}`)}</div> */}

                    <Button label={t("modals.send")} color="green" onClick={acceptHandler} isNeededStar={false} />
                </>
            )}
            {step === 4 && (
                <>
                    <div className={styles.successTitle}>{t("modals.withdraw-success")}</div>
                    {!location.pathname.includes("/account/history") && (
                        <>
                            <Link
                                to={"/account/history?type=OUTCOME"}
                                className={[styles.link, styles.history].join(" ")}
                                onClick={() => dispatch(setCurrentModal(null))}
                            >
                                <b>{t("cabinet.operations-history-link")}</b> <i>→</i>
                            </Link>
                        </>
                    )}
                    <div className={styles.finish}>
                        <div>
                            <span>
                                {value} {t("currency")}
                            </span>
                            <span>{t("modals.withdraw-success-2")}</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
