import { IUser } from "../types/User";
import Avatar from "../assets/avatar.svg";

const useLocalUser = (user?: IUser) => {
    const avatar = user?.avatar ? (user.avatar.includes("/") ? user.avatar : `${window.location.origin}/avatar/${user.avatar}`) : Avatar;
    const userName = user?.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user?.login;
    return { userName, avatar };
};

export default useLocalUser;
