import React from "react";
import logo from "./../../../assets/img/svg/logo.svg";
import style from "./Logo.module.scss";
import { Link } from "react-router-dom";

interface LogoProps {
    className?: string;
}

const Logo = (props: LogoProps) => {
    return (
        <div className={[style.logo, props.className].join(" ")}>
            <Link to={"/"}>
                <img src={logo} alt="SAIRUS" />
            </Link>
        </div>
    );
};

export default Logo;
