// @ts-ignore
import styles from "./AddWallet.module.scss";
import { AddWalletProps } from "./AddWallet.types";
import { Wallet } from "../Wallets/Wallets";
import Button from "../Button";
// import { Link, useLocation } from "react-router-dom";
import { IUserWallet } from "../../types/User";
import { useApiContext } from "../../contexts/ApiContext";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeWalletNumber, setCurrentWallet, setWallets } from "../../app/reducers/wallets/wallet.slice";
import { setModalStep } from "../../app/reducers/modal/modal.slice";
import ModalStepTitle from "../UI/ModalStepTitle/ModalStepTitle";
import { TextField } from "../UI/TextField";
import { setError } from "../../app/reducers/error/error.slice";

const data: IUserWallet[] = [
    {
        label: "Payeer",
        paymentSystem: "PAYEER",
        currency: "USD",
        wallet: "",
    },
    {
        label: "PM",
        paymentSystem: "PERFECT_MONEY",
        currency: "USD",
        wallet: "",
    },
    {
        label: "USDT",
        paymentSystem: "USDT",
        currency: "USDT",
        wallet: "",
    },
];

export default function AddWallet(props: AddWalletProps) {
    const { response } = useApiContext();
    const isPhone = useMediaQuery("(max-width: 767.98px)");
    const { t } = useTranslation();

    // const location = useLocation();

    const {
        wallet: { currentWallet: wallet, wallets },
        modal: { modalStep: step },
    } = useAppSelector((state) => state);

    const dispatch = useAppDispatch();

    const handler = () => {
        const data = JSON.stringify({
            currency: wallet.currency,
            paymentSystem: wallet.paymentSystem,
            wallet: wallet.wallet,
        });

        response("/user/wallet", "POST", data).then((res) => {
            if (res.code === "OK") {
                if (res.result) dispatch(setWallets(res.result));
                else dispatch(setWallets([]));
                dispatch(setModalStep(3));
            } else dispatch(setError(res.error.message));
        });
    };

    const filter = (wallet: IUserWallet): boolean => {
        if (wallets.find((item) => item.paymentSystem === wallet.paymentSystem)) return false;
        else return true;
    };

    return (
        <div className={styles.addWallet}>
            {step !== 3 && (
                <div className={`h3 text-bold ${!isPhone ? "text-center" : "text-left"} ${styles.backWrapper}`}>
                    {step !== 1 && (
                        <span className={[styles.link, styles.back].join(" ")} onClick={() => dispatch(setModalStep(1))}>
                            <i>←</i> <b>{t("modals.back")}</b>
                        </span>
                    )}
                </div>
            )}
            {step === 1 && (
                <>
                    <ModalStepTitle step={1} text={t("modals.choose-payment-system")} />
                    <div className={styles.list}>
                        {data.filter(filter).map((wallet) => (
                            <Wallet
                                color={"#A6A6A6"}
                                onClick={() => {
                                    dispatch(setCurrentWallet(wallet));
                                    dispatch(setModalStep(2));
                                }}
                                wallet={wallet}
                                key={wallet.paymentSystem}
                            />
                        ))}
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <ModalStepTitle step={2} text={t("modals.enter-wallet-address")} />

                    <div style={{ marginTop: "20px" }}>{t("modals.wallet-address")}</div>

                    <TextField
                        className={styles.textField}
                        value={wallet.wallet}
                        placeholder={t("modals.address-placeholder", [wallet?.label]) || undefined}
                        fullWidth={true}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(changeWalletNumber(e.target.value))}
                    />

                    <Button label={t("cabinet.create-wallet")} className={styles.button} onClick={handler} isNeededStar={false} />
                </>
            )}
            {step === 3 && (
                <>
                    <Wallet wallet={wallet} />
                    <div className={styles.title}>{t("modals.create-sucsses", [t(`wallets.${wallet?.paymentSystem}`)])}</div>
                    {/* {!location.pathname.includes("/account/settings") && (
                        <>
                            <div style={{ marginTop: "15px" }}>{t("modals.create-sucsses-after")}</div>
                            <Link to={"/account/settings/"} className={[styles.text, "text-green"].join(" ")}>
                                {t("cabinet.account-settings")}
                            </Link>
                        </>
                    )} */}
                </>
            )}
        </div>
    );
}
