import styles from "./AuthForm.module.scss";
import { AuthFormProps } from "./AuthForm.types";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ColorCard from "../SAIRUS_NEW/ColorCard/ColorCard";
import { TextField } from "../UI/TextField";
import { Checkbox } from "../UI/Checkbox";
import { useAppDispatch } from "../../app/hooks";
import { setCurrentModal } from "../../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../../app/reducers/modal/modal.types";

export default function AuthForm(props: AuthFormProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <>
            <ColorCard
                buttonLabel={props.buttonLabel}
                color={props.color}
                onClick={props.handler}
                title={props.title}
                buttonWidth={250}
                style={{ maxHeight: "none" }}
            >
                <div className={[styles.formContent, styles[props.color]].join(" ")}>
                    <Row>
                        {props.inputs.map((input, index) => (
                            <Col key={index} {...input.col}>
                                <TextField
                                    value={input.input.value}
                                    onChange={input.input.onChange}
                                    type={input.input.type}
                                    fullWidth={true}
                                    error={input.input.error}
                                    label={input.input.label}
                                    labelStyle={{ textAlign: "center", marginBottom: 5 }}
                                    textfieldContainerStyle={input.input.textfieldContainerStyle}
                                >
                                    <i className={`icon-${input.input.icon}`} />
                                </TextField>
                            </Col>
                        ))}
                        <Col sm={12} md={window.location.pathname === "/registration" ? 6 : 12}>
                            <div className={styles.captcha}>{props.captcha}</div>
                        </Col>
                        {props.accept && (
                            <div className={styles.accept}>
                                <Checkbox
                                    name="accept"
                                    checked={props.accept[0]}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.accept && props.accept[1](e.target.checked);
                                    }}
                                />
                                {/* <input
                                    id="accept"
                                    type="checkbox"
                                    checked={props.accept[0]}
                                    onChange={(e) => {
                                        props.accept && props.accept[1](e.target.checked);
                                    }}
                                /> */}
                                <label htmlFor="accept" className={props.acceptError ? styles.error : ""} />
                                <span className={props.acceptError ? styles.error : ""}>
                                    {t("auth.accept")}{" "}
                                    <span onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.CONF))}>{t("auth.accept-link")}</span>
                                </span>
                            </div>
                        )}
                    </Row>
                </div>
            </ColorCard>

            <div className={[styles.bottom, styles[props.color]].join(" ")}>
                {props.links.map((link, index) => (
                    <div key={index} className={styles.linkWrapper}>
                        <a
                            className={[styles.link, styles[props.color]].join(" ")}
                            href={link.href}
                            onClick={(e) => {
                                if (link.handler) {
                                    e.preventDefault();
                                    link.handler();
                                }
                            }}
                        >
                            {link.text}
                        </a>
                    </div>
                ))}
            </div>

            {/* <div className={styles.form}>
                <div className={["h1", styles.h1].join(" ")}>{props.title}</div>
                {props.text && <p style={{ fontSize: "20px" }}>{props.text}</p>}
                <Row>
                    {props.inputs.map((input, index) => (
                        <Col key={index} {...input.col}>
                            <Input {...input.input} />
                        </Col>
                    ))}
                </Row>
                
            </div> */}
        </>
    );
}
