import React from "react";
import styles from "./ModalStepTitle.module.scss";
import { useTranslation } from "react-i18next";

interface Props extends React.HTMLProps<HTMLDivElement> {
    text: string;
    step: 1 | 2 | 3;
}
const ModalStepTitle: React.FC<Props> = ({ text, step }) => {
    const { t } = useTranslation();

    return (
        <div className={[styles.wrapper, styles[`step-${step}`]].join(" ")}>
            <p>
                {t(`modals.step`)} {step}
            </p>
            <p>{text}</p>
        </div>
    );
};

export default ModalStepTitle;
