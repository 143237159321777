import React from "react";
import { CircleButton } from "../../UI/CircleButton";
import styles from "./SocialLinks.module.scss";

interface Props {
    className?: string;
}
const SocialLinks = (props: Props) => {
    return (
        <div className={[styles.wrapper, props.className].join(" ")}>
            <CircleButton
                iconName="twitter"
                dotStyle={{ backgroundColor: "transparent" }}
                onClick={() => window.open("https://twitter.com/Sairus_Official ", "_blank")}
            />
            <CircleButton iconName="instagram" dotStyle={{ backgroundColor: "transparent" }} onClick={() => window.open("", "_blank")} />
            <CircleButton
                iconName="telegram"
                dotStyle={{ backgroundColor: "transparent" }}
                onClick={() => window.open("https://t.me/SairusEnglish", "_blank")}
            />
            <CircleButton
                iconName="youtube"
                dotStyle={{ backgroundColor: "transparent" }}
                onClick={() => window.open("https://www.youtube.com/@SairusOfficial", "_blank")}
            />
        </div>
    );
};

export default SocialLinks;
