import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IErrorState } from "./error.types";

const initialState: IErrorState = {
    isShow: false,
    error: "",
};

const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        hideError: (state) => {
            state.isShow = false;
        },
        resetError: (state) => {
            state.error = "";
        },
        setError: (state, action: PayloadAction<string>) => {
            state.isShow = true;
            state.error = action.payload;
        },
    },
});

export const { setError, resetError, hideError } = errorSlice.actions;

export default errorSlice.reducer;
