import { FC } from "react";
import { MessengerButtonProps } from ".";
import { CircleButton } from "../CircleButton";
import styles from "./MessengerButton.module.scss";
import { Link } from "react-router-dom";

export const MessengerButton: FC<MessengerButtonProps> = (props) => {
    return (
        <div className={[styles.messengerButton, props.className].join(" ")}>
            <Link
                to={"/account/messenger/"}
                className={styles.icon}
                style={{ display: "flex", justifyContent: "center", alignItems: "center", }}
            >
                <div className={styles.messengerButton__star}></div>
                <CircleButton
                    onClick={props.onClick}
                    iconName="messenger"
                    style={{
                        boxShadow: "0 0 5px 5px #df4bff",
                    }}
                    iconStyle={{
                        backgroundColor: "#df4bff",
                    }}
                    dotStyle={{
                        backgroundColor: "#df4bff",
                    }}
                />
            </Link>
        </div>
    );
};
