import React, { useEffect } from "react";
import styles from "./Error.module.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { hideError, resetError } from "../../../app/reducers/error/error.slice";
import { useTranslation } from "react-i18next";

const Error = () => {
    const { error, isShow } = useAppSelector((state) => state.error);
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (isShow) {
            setTimeout(() => dispatch(hideError()), 3000);
            setTimeout(() => dispatch(resetError()), 4000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);
    return <div className={[styles.error, isShow ? styles.show : ""].join(" ")}>{error ? t(`errors.${error}`) : ""}</div>;
};

export default Error;
