import React, { lazy } from "react";
import "./App.scss";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import "./styles/basic.scss";
import "swiper/css";

import ApiProvider from "./components/ApiProvider";
import "./i18n";
import "moment/locale/ru";
import ActivationPage from "./pages/ActivationPage";
import { Provider } from "react-redux";
import { store } from "./app/store";
import LandingWrapper from "./components/SAIRUS_NEW/LandingWrapper/LandingWrapper";
import AccountWrapper from "./components/AccountWrapper";
import InstructionPage from "./pages/InstructionPage";
// import P2PPage from "./pages/P2PPage";
import Error from "./components/UI/Error/Error";
import ErrorPage from "./pages/ErrorPage";

// import LandingPage from "./pages/LandingPage";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const CharityPage = lazy(() => import("./pages/CharityPage"));
const MarketingPage = lazy(() => import("./pages/MarketingPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const RegistrationPage = lazy(() => import("./pages/RegistrationPage"));
const ResetPage = lazy(() => import("./pages/ResetPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const BalancePage = lazy(() => import("./pages/BalancePage"));
const HistoryPage = lazy(() => import("./pages/HistoryPage"));
const TeamPage = lazy(() => import("./pages/TeamPage"));
const MaterialsPage = lazy(() => import("./pages/MaterialsPage"));
const SettingsPage = lazy(() => import("./pages/SettingsPage"));
const MessengerPage = lazy(() => import("./pages/MessengerPage"));
const WorkingPage = lazy(() => import("./pages/WorkingPage"));
const P2PPage = lazy(() => import("./pages/P2PPage"));


const getPaymentResultChildren = (result: boolean) => {
    return [
        {
            path: "account",
            children: [
                {
                    path: "",
                    element: <Navigate to={`/account/?success=${result}`} />,
                },
                {
                    path: "balance",
                    element: <Navigate to={`/account/balance?success=${result}`} />,
                },
                {
                    path: "history",
                    element: <Navigate to={`/account/history?success=${result}`} />,
                },
                {
                    path: "team",
                    element: <Navigate to={`/account/team?success=${result}`} />,
                },
                {
                    path: "materials",
                    element: <Navigate to={`/account/materials?success=${result}`} />,
                },
                {
                    path: "settings",
                    element: <Navigate to={`/account/settings?success=${result}`} />,
                },
                {
                    path: "messenger",
                    element: <Navigate to={`/account/messenger?success=${result}`} />,
                },
            ],
        },
    ];
};

// основной роутер (вынести в отдельный файл)
const router = createBrowserRouter([
    {
        path: "/",
        element: <LandingWrapper />,
        errorElement: <ErrorPage />,
        children: [
            { path: "", element: <LandingPage /> },
            { path: "login", element: <LoginPage /> },
            {
                path: "registration",
                element: <RegistrationPage />,
            },
            {
                path: "reset",
                element: <ResetPage />,
            },
            {
                path: "resend-activation",
                element: <ActivationPage />,
            },
            {
                path: "charity",
                element: <CharityPage />,
            },
            {
                path: "marketing",
                element: <MarketingPage />,
            },
            {
                path: "p2p",
                element: <P2PPage />,
            },
            {
                path: "technical-works",
                element: <WorkingPage />,
            },
        ],
    },

    {
        path: "account",
        element: <AccountWrapper />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <DashboardPage />,
            },
            {
                path: "balance",
                element: <BalancePage />,
            },
            {
                path: "history",
                element: <HistoryPage />,
            },
            {
                path: "team",
                element: <TeamPage />,
            },
            {
                path: "materials",
                element: <MaterialsPage />,
            },
            {
                path: "settings",
                element: <SettingsPage />,
            },
            {
                path: "messenger",
                element: <MessengerPage />,
            },
            {
                path: "instructions",
                element: <InstructionPage />,
            },
        ],
    },
    {
        path: `payment/success`,
        children: [...getPaymentResultChildren(true)],
    },
    {
        path: `payment/failed`,
        children: [...getPaymentResultChildren(false)],
    },
]);

function App() {
    return (
        <>
            <Provider store={store}>
                <ApiProvider>
                    <div className="App">
                        <RouterProvider router={router} />
                        <Error />
                    </div>
                </ApiProvider>
            </Provider>
        </>
    );
}

export default App;
