import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IModalState, ModalsTypesEnum } from "./modal.types";

const initialState: IModalState = {
    modalStep: 1,
    currentModal: null,
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setModalStep: (state, action: PayloadAction<number>) => {
            state.modalStep = action.payload;
        },
        setCurrentModal: (state, action: PayloadAction<ModalsTypesEnum | null>) => {
            state.currentModal = action.payload;
            state.modalStep = 1;
        },
    },
});

export const { setModalStep, setCurrentModal } = modalSlice.actions;

export default modalSlice.reducer;
