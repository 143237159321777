import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBalanceItem, IBalanceState } from "./balance.types";

const initialState: IBalanceState = {
    balances: [],
    balance: 0,
};

const balanceSlice = createSlice({
    name: "balance",
    initialState,
    reducers: {
        setBalances: (state, action: PayloadAction<IBalanceItem[]>) => {
            state.balances = action.payload;
        },
        setBalance: (state, action: PayloadAction<number>) => {
            state.balance = action.payload;
        },
    },
});

export const { setBalances, setBalance } = balanceSlice.actions;

export default balanceSlice.reducer;
