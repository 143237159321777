import { FC } from "react";
import { CircleButtonProps } from ".";
import styles from "./CircleButton.module.scss";

export const CircleButton: FC<CircleButtonProps> = (props) => {
    return (
        <div className={[styles.circleButton, props.className].join(" ")} onClick={props.onClick} style={{ ...props.style }}>
            <i className={`${styles.circleButton__icon} icon-${props.iconName}`} style={{ ...props.iconStyle }} />
            <div className={[styles.dot, styles.bold, styles.dot_1].join(" ")} style={{ ...props.dotStyle }}></div>
            <div className={[styles.dot, styles.dot_2].join(" ")} style={{ ...props.dotStyle }}></div>
            <div className={[styles.dot, styles.bold, styles.dot_3].join(" ")} style={{ ...props.dotStyle }}></div>
            <div className={[styles.dot, styles.dot_4].join(" ")} style={{ ...props.dotStyle }}></div>
        </div>
    );
};
