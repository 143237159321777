import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ISubscribeState {
    isSubscribed: boolean;
}

const initialState: ISubscribeState = {
    isSubscribed: false,
};

const subscribeSlice = createSlice({
    name: "subscribe",
    initialState,
    reducers: {
        setIsSubscribed: (state, action: PayloadAction<boolean>) => {
            state.isSubscribed = action.payload;
        },
    },
});

export const { setIsSubscribed } = subscribeSlice.actions;

export default subscribeSlice.reducer;
