import React, { FC, useState } from "react";
import { TextFieldProps } from ".";
import styles from "./TextField.module.scss";

const MAX_AMOUNT = 1000000;

export const TextField: FC<TextFieldProps> = (props) => {
    const [success, setSuccess] = useState(props.success);
    const [error, setError] = useState(props.error);
    // const [disabled, setDisabled] = useState(props.disabled);
    const [placeholder, setPlaceholder] = useState(props.placeholder);

    const textfield_styles = () => {
        let output = [styles.textfield];
        if (props.small) output.push(styles.textfield_small);
        if (props.error) output.push(styles.textfield_error);
        if (success) output.push(styles.textfield_success);
        if (props.disabled) output.push(styles.textfield_disabled);
        return output.join(" ");
    };

    return (
        <div className={[styles.container, props.className].join(" ")} style={{ ...props.textfieldContainerStyle }}>
            <label className={props.small ? [styles.label, styles.label_small].join(" ") : styles.label} style={{ ...props.labelStyle }}>
                {props.label}
            </label>
            <div
                className={textfield_styles()}
                style={{
                    width: props.fullWidth ? "100%" : "auto",
                    maxWidth: props.fullWidth ? "none" : 268,
                    ...props.textfieldStyle,
                }}
            >
                <input
                    ref={props.ref}
                    type={props.type}
                    className={styles.textfield__text}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    style={{ ...props.inputStyle }}
                    value={props.value || ""}
                    min={props.min || 10}
                    max={MAX_AMOUNT}
                ></input>
                {error && <i className={`icon-error ${styles.iconError}`} />}
                {success && !props.error && <i className={`icon-success ${styles.iconSuccess}`} />}
                {!error && !success && props.children}
            </div>
        </div>
    );
};
