export enum ModalsTypesEnum {
    UPDATE = "UPDATE",
    ADD = "ADD",
    REMOVE = "REMOVE",
    REPLENISH = "REPLENISH",
    WITHDRAW = "WITHDRAW",
    TRANSFER = "TRANSFER",
    SUCCESS_PAYMENT = "SUCCESS_PAYMENT",
    FAILED_PAYMENT = "FAILED_PAYMENT",
    SUCCESSFULL_REGISTRATION = "SUCCESSFULL_REGISTRATION",
    SUCCESSFULL_SEND_ACTIVATE_LINK = "SUCCESSFULL_SEND_ACTIVATE_LINK",
    SUCCESSFULL_SEND_NEW_PASSWORD = "SUCCESSFULL_SEND_NEW_PASSWORD",
    SUCCESSFULL_SEND_INSTRUCTION = "SUCCESSFULL_SEND_INSTRUCTION",
    SUCCESSFULL_ACTIVATION = "SUCCESSFULL_ACTIVATION",
    FAILED_ACTIVATION = "FAILED_ACTIVATION",
    DEPOSIT_CREATED = "DEPOSIT_CREATED",
    SUCCESSFULL_SAVE = "SUCCESSFULL_SAVE",
    NEWS = "NEWS",
    NEWS_ITEM = "NEWS_ITEM",
    VIDEO = "VIDEO",
    CONF = "CONF",
    TECHNICAL_WORKS = "TECHNICAL_WORKS",
}

export interface IModalState {
    modalStep: number;
    currentModal: ModalsTypesEnum | null;
}
