import React from "react";
import Container from "../Container/Container";
import Logo from "../Logo/Logo";
import styles from "./LandingHeader.module.scss";
import LandingMenu from "../LandingMenu/LandingMenu";
import { AddUserButton } from "../../UI/AddUserButton";
import { LockButton } from "../../UI/LockButton";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuButton } from "../../UI/MenuButton";
import { useMenuContext } from "../../../contexts/MenuContext";
import { useApiContext } from "../../../contexts/ApiContext";

const LandingHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { isAuth } = useApiContext();

    const { open, setOpen } = useMenuContext();

    return (
        <section
            className={[
                styles.wrapper,
                location.pathname === "/" || location.pathname === "/marketing" ? styles.bg : "",
                open ? styles.open : "",
            ].join(" ")}
        >
            <Container>
                <div className={styles.body}>
                    <Logo />
                    <div className={styles.menu}>
                        <div className={[styles.buttons, isAuth ? styles.auth : ""].join(" ")}>
                            {isAuth ? (
                                <LockButton onClick={() => navigate("/account")} />
                            ) : (
                                <>
                                    <LockButton className={styles.signinBtn} onClick={() => window.location.replace("/login")} />
                                    <AddUserButton className={styles.signupBtn} onClick={() => window.location.replace("/registration")} />
                                </>
                            )}
                            <MenuButton
                                open={open}
                                className={styles.menuBtn}
                                onClick={() => {
                                    setOpen((prev) => !prev);
                                }}
                            />
                        </div>
                        <LandingMenu />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default LandingHeader;
