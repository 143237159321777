import React, { useState, useEffect } from "react";
import styles from "./AccountHeaderMenu.module.scss";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { CircleButton } from "../../UI/CircleButton";
import { setCurrentModal, setModalStep } from "../../../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../../../app/reducers/modal/modal.types";
import { useTranslation } from "react-i18next";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setBalance } from "../../../app/reducers/balance/balance.slice";
import Avatar from "../../../assets/avatar.svg";
import { useMenuContext } from "../../../contexts/MenuContext";
import LanguageSwitcher from "../../LanguageSwitcher";

const AccountHeaderMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [balanceRefreshPending, setBalanceRefreshPending] = useState(false);
    const [level, setLevel] = useState(1);
    const [visibleHint, setVisibleHint] = useState(false);
    const [expandUserControls, setExpandUserControls] = useState(false);
    const { balance } = useAppSelector((state) => state.balance);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { user, logout, response } = useApiContext();
    const { open } = useMenuContext();
    const userName = user?.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user?.login;

    useEffect(() => {
        if (!location.search.includes("success")) return;
        if (location.search.includes("false")) {
            dispatch(setCurrentModal(ModalsTypesEnum.FAILED_PAYMENT));
            localStorage.setItem("PAYMENT_STATUS", "FAILED");
        } else {
            dispatch(setCurrentModal(ModalsTypesEnum.SUCCESS_PAYMENT));
            localStorage.setItem("PAYMENT_STATUS", "SUCCESS");
        }
        dispatch(setModalStep(3));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const { avatarSrc } = useAppSelector((state) => state.avatar);
    const avatar = avatarSrc ? `${window.location.origin}/avatar/${avatarSrc}` : Avatar;

    const refreshBalance = async () => {
        const start = Date.now();
        setBalanceRefreshPending(true);
        let newBalance = await response("/user/balance");
        let final = Date.now();

        while ((final - start) % 1000 !== 0) {
            final = Date.now();
        }

        dispatch(setBalance(Number(newBalance.result)));
        setBalanceRefreshPending(false);
    };

    const getLevel = () => {
        if (user?.bonus8Paid) return 8;
        if (user?.bonus7Paid) return 7;
        if (user?.bonus6Paid) return 6;
        if (user?.bonus5Paid) return 5;
        if (user?.bonus4Paid) return 4;
        if (user?.bonus3Paid) return 3;
        if (user?.bonus2Paid) return 2;
        if (user?.bonus1Paid) return 1;
        return 0;
    };

    useEffect(() => {
        setLevel(getLevel());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const toggleHint = () => {
        setVisibleHint((prev) => !prev);
    };

    return (
        <nav className={[styles.accountHeaderMenu, open ? styles.menuOpen : ""].join(" ")}>
            <div className={styles.menuItem} onClick={refreshBalance}>
                <div className={styles.iconWrapper}>
                    <i className={[balanceRefreshPending ? styles.refreshPending : styles.refresh, "icon-refresh"].join(" ")} />
                </div>
                <div className={styles.textWrapper}>
                    <span className={styles.balanceLabel}>{t("cabinet.balance")}</span>
                    <span className={styles.balanceValue}>
                        {balance.toFixed(2)} {t("currency")}
                    </span>
                </div>
            </div>
            <div className={[styles.menuItem].join(" ")} onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.REPLENISH))}>
                <div className={styles.iconWrapper}>
                    <i className="icon-cash-in" />
                </div>
                <div className={styles.textWrapper}>{t("cabinet.replenish-balance")}</div>
            </div>
            <div className={[styles.menuItem].join(" ")} onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.WITHDRAW))}>
                <div className={styles.iconWrapper}>
                    <i className="icon-cash-out" />
                </div>
                <div className={styles.textWrapper}>{t("cabinet.withdraw")}</div>
            </div>
            <div className={[styles.menuItem].join(" ")} onClick={() => dispatch(setCurrentModal(ModalsTypesEnum.TRANSFER))}>
                <div className={styles.iconWrapper}>
                    <i className="icon-transfer" />
                </div>
                <div className={styles.textWrapper}>{t("cabinet.fund-transfer")}</div>
            </div>
            <div className={[styles.menuItem].join(" ")} onClick={() => navigate("/account/settings")}>
                <div className={styles.iconWrapper}>
                    <i className="icon-wallet-mgm" />
                </div>
                <div className={styles.textWrapper}>{t("cabinet.wallet-management")}</div>
            </div>
            <div
                className={[styles.menuItem, styles.user].join(" ")}
                onClick={() => {
                    setExpandUserControls(!expandUserControls);
                }}
            >
                <div className={styles.avatar}>
                    <img src={avatar} alt="avatar" />
                </div>
                <div className={styles.userInfo}>
                    <div className={styles.userName}>{userName && userName.length > 20 ? userName.slice(0, 17) + "..." : userName}</div>
                    <p className={[styles.email, "text-xs"].join(" ")}>
                        {user?.email && user.email.length > 20 ? user.email.slice(0, 17) + "..." : user?.email}
                    </p>
                </div>
                <button className={[styles.buttonControlsExpand, expandUserControls && styles.expand].join(" ")}>
                    <i className="icon-mark-mini" />
                </button>
                <div className={[styles.userControls, expandUserControls && styles.expand].join(" ")}>
                    <div
                        className={styles.currentRank}
                        onMouseEnter={toggleHint}
                        onMouseLeave={toggleHint}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleHint();
                        }}
                    >
                        <span>R{level}</span>
                        <span className={[styles.hint, visibleHint ? styles.active : ""].join(" ")}>{t("cabinet.hint")}</span>
                    </div>
                    <LanguageSwitcher className={styles.lang} />
                    <CircleButton className={styles.logout} iconName="exit" dotStyle={{ display: "none" }} onClick={logout} />
                </div>
            </div>
            <div className={[styles.menuItem].join(" ")}>
                <Link
                    to={"/account/messenger/"}
                    className={styles.icon}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "55%" }}
                >
                    <CircleButton className={styles.messenger} iconName="messenger" dotStyle={{ display: "none" }} />
                </Link>
            </div>
        </nav>
    );
};

export default AccountHeaderMenu;
