import { FC } from "react";
import { GlowingIconProps } from ".";
import styles from "./GlowingIcon.module.scss";

export const GlowingIcon: FC<GlowingIconProps> = (props) => {
    const color = (colorProp?: string): string => {
        switch (colorProp) {
            case "magenta":
                return "#df4bff";
            case "purple":
                return "#8700fd";
            case "blue":
                return "#0066ff";
            default:
                return "#0066ff";
        }
    };

    return (
        <img
            src={`/svg/${props.name}.svg`}
            className={[styles.glowingIcon, props.className].join(" ")}
            style={{
                filter: `drop-shadow(0 0 ${props.blurRadius ? props.blurRadius : 16}px ${color(props.color)}`,
            }}
            alt={props.name}
        />
    );
};
