import React, { ReactNode } from "react";
import styles from "./ColorCard.module.scss";
import Button from "../../Button/Button";

interface Props extends React.HTMLProps<HTMLDivElement> {
    color: "magenta" | "cian" | "purple" | "azure";
    title?: string;
    children: ReactNode;
    onClick?: () => void;
    buttonLabel?: string;
    buttonWidth?: number;
}

const ColorCard: React.FC<Props> = ({ color, title, children, onClick, buttonLabel, buttonWidth, className, style }) => {
    const getWrapperStyles = (color: string): string => {
        const result = [styles.wrapper];
        switch (color) {
            case "magenta":
                result.push(styles.wrapper_magenta);
                break;
            case "purple":
                result.push(styles.wrapper_purple);
                break;
            case "cian":
                result.push(styles.wrapper_cian);
                break;
            default:
                result.push(styles.wrapper_magenta);
                break;
        }
        return result.join(" ");
    };

    const getTypeStyles = (color: string): string => {
        const result = [styles.type];
        switch (color) {
            case "magenta":
                result.push(styles.type_magenta);
                break;
            case "purple":
                result.push(styles.type_purple);
                break;
            case "cian":
                result.push(styles.type_cian);
                break;
            default:
                result.push(styles.type_magenta);
                break;
        }
        return result.join(" ");
    };

    return (
        <div className={[styles.calculator, className].join(" ")}>
            <h3 className={getTypeStyles(color)}>
                <div className={`${styles.star} ${color}`}></div>
                {title}
                <div className={`${styles.star} ${color}`}></div>
            </h3>
            <div className={[getWrapperStyles(color), buttonLabel ? styles.withBtn : ""].join(" ")} style={style}>
                {children}
                {buttonLabel && (
                    <Button
                        label={buttonLabel}
                        color={color}
                        className={styles.button}
                        onClick={onClick}
                        style={{ width: buttonWidth }}
                        isNeededStar={false}
                    />
                )}
            </div>
        </div>
    );
};

export default ColorCard;
