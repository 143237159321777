// @ts-ignore
import { ApiProviderProps } from "./ApiProvider.types";
import ApiContext, { IApiContext, loginType, responseType } from "../../contexts/ApiContext";
import { useEffect, useState } from "react";
import { IUser } from "../../types/User";
import { createAuthString } from "../../utils/createAuthString";
import { useAppDispatch } from "../../app/hooks";
import { setWallets } from "../../app/reducers/wallets/wallet.slice";
import { setBalance } from "../../app/reducers/balance/balance.slice";
import { setAvatarSrc } from "../../app/reducers/avatar/avatar.slice";
import { setIsSubscribed } from "../../app/reducers/subscribe/subscribe.slice";
import { setCurrentModal } from "../../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../../app/reducers/modal/modal.types";

const Provider = ApiContext.Provider;

export default function ApiProvider(props: ApiProviderProps) {
    const [user, setUser] = useState<IUser | undefined>();
    const [authString, setAuthString] = useState<string | null>(localStorage.getItem("auth"));
    const [serverDisabled, setServerDisabled] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const apiPath: string = window.location.hostname === "localhost" ? "http://64.176.64.218:8091/api" : `${window.location.origin}/api`;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!authString) setIsLoading(false);
        else reload().then(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authString]);

    useEffect(() => {
        if (!isAuth) return;
        response("/user").then((res) => {
            if (res.code === "OK") {
                setUser(res.result as IUser);
                dispatch(setIsSubscribed(res.result.sendEmails));
                if (res.result.wallets) dispatch(setWallets(res.result.wallets));
                else dispatch(setWallets([]));
                if (res.result.balances) dispatch(setBalance(Number(res.result.balances[0].amount)));
                else dispatch(setBalance(0));
                if (res.result.avatar) dispatch(setAvatarSrc(`${res.result.avatar}?${Date.now()}`));
                else dispatch(setAvatarSrc(""));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    const response: responseType = async (path, method, body) => {
        const url = decodeURI(apiPath + path);
        const headers: HeadersInit = {};

        if (isAuth && !path.includes("/register") && !path.includes("/login") && !path.includes("/activity/link"))
            headers["Authorization"] = "Basic " + localStorage.getItem("auth");

        const res = await fetch(url, {
            method: method || "GET",
            // mode: "no-cors",
            headers: headers,
            body,
        });
        // const r = await res.json()
        // if (r.code === "UNAUTHORIZED")
        //     logout()
        return res.json();
    };

    const login: loginType = async (login: string, pwd: string, token: string) => {
        const res = await response(`/login?login=${login}&pwd=${pwd}&token=${token}`, "POST", JSON.stringify({ login, pwd, token }));
        if (res.code === "OK") {
            setUser(res.result as IUser);
            const b64 = createAuthString(res.result.login, pwd);
            localStorage.setItem("auth", b64);
            setAuthString(b64);
        }

        return res;
    };

    const logout = () => {
        localStorage.removeItem("auth");
        setAuthString(null);
        setUser(undefined);
        setIsAuth(false);
    };

    const reload = async () => {
        if (window.location.pathname === "/technical-works") return;
        if (serverDisabled) return;

        try {
            const res = await fetch(apiPath + "/auth", {
                headers: {
                    Authorization: "Basic " + authString,
                },
            });
            const json = await res.json();

            setIsAuth(json.result);

            return json;
        } catch (e) {
            setIsAuth(false);
            setServerDisabled(true);
            dispatch(setCurrentModal(ModalsTypesEnum.TECHNICAL_WORKS));
        }
    };

    const updateUser = async () => {
        try {
            const res = await response("/user");
            if (res.code === "OK") {
                setUser(res.result as IUser);
            }
        } catch (error) {
            window.location.replace("/");
            dispatch(setCurrentModal(ModalsTypesEnum.TECHNICAL_WORKS));
        }
    };

    const value: IApiContext = {
        isAuth,
        isLoading,
        user,
        response,
        login,
        logout,
        reload,
        updateUser,
        setUser,
        setAuthString,
    };

    return <Provider value={value}>{props.children}</Provider>;
}
