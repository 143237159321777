// @ts-ignore
import styles from "./Replenish.module.scss";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { setCurrentModal } from "../../app/reducers/modal/modal.slice";
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";

export default function PerfectMoneyResult() {
    const { t } = useTranslation();

    const location = useLocation();

    const dispatch = useAppDispatch();

    return (
        <>
            {localStorage.getItem("PAYMENT_STATUS") === "SUCCESS" ? (
                <>
                    <div className={styles.successTitle}>{t("modals.payment-success")}</div>
                    <div className={styles.successText}>{t("modals.payment-status")}</div>
                    {!location.pathname.includes("/account/history") && (
                        <>
                            <Link
                                to={"/account/history?type=INCOME"}
                                className={[styles.link, styles.history].join(" ")}
                                onClick={() => dispatch(setCurrentModal(null))}
                            >
                                <b>{t("cabinet.operations-history-link")}</b> <i>→</i>
                            </Link>
                        </>
                    )}

                    <div className={styles.finish}>
                        <span>{t("modals.payment-success-2")}</span>
                        <br />
                        <span>
                            {Number(localStorage.getItem("PAYMENT_AMOUNT")).toFixed(2)} {t("currency")}
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.successTitle}>{t("modals.payment-fail")}</div>
                    <div className={styles.error}>
                        <div>{t("modals.payment-fail-2")}</div>
                        <div className="text-red">
                            {Number(localStorage.getItem("PAYMENT_AMOUNT")).toFixed(2)} {t("currency")}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
