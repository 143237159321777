import React from "react";
import styles from "./Faq.module.scss";
import Container from "../Container/Container";
import SectionTitle from "../SectionTitle/SectionTitle";
import FaqItem, { IFaqItem } from "./FaqItem";
import { useTranslation } from "react-i18next";

interface Props {
    isCabinet?: boolean;
}

const Faq: React.FC<Props> = ({ isCabinet }) => {
    const { t } = useTranslation();

    const faqList = t("faq.items", { returnObjects: true }) as IFaqItem[];

    const col_1 = faqList.slice(0, Math.floor(faqList.length / 2));
    const col_2 = faqList.slice(Math.floor(faqList.length / 2));

    return (
        <>
            {isCabinet ? (
                <div className={styles.questionsList}>
                    <div className={styles.col}>
                        {col_1.map((q, i) => (
                            <FaqItem key={i} question={q.question} answer={q.answer} />
                        ))}
                    </div>
                    <div className={styles.col}>
                        {col_2.map((q, i) => (
                            <FaqItem key={i} question={q.question} answer={q.answer} />
                        ))}
                    </div>
                </div>
            ) : (
                <div className={styles.wrapper} id="faq">
                    <Container>
                        <SectionTitle
                            text={t("landing.faq.title")}
                            gradientText={t("landing.faq.gradientTitle")}
                            className={styles.title}
                        />
                        <div className={styles.questionsList}>
                            <div className={styles.col}>
                                {col_1.map((q, i) => (
                                    <FaqItem key={i} question={q.question} answer={q.answer} />
                                ))}
                            </div>
                            <div className={styles.col}>
                                {col_2.map((q, i) => (
                                    <FaqItem key={i} question={q.question} answer={q.answer} />
                                ))}
                            </div>
                        </div>
                    </Container>
                </div>
            )}
        </>
    );
};

export default Faq;
