import { Dispatch, SetStateAction, createContext, useContext } from "react";
import { IUser } from "../types/User";

export type responseType = (path: string, method?: string, body?: any) => Promise<any>;

export type loginType = (login: string, pwd: string, token: string) => Promise<any>;
export interface IApiContext {
    isAuth: boolean;
    isLoading: boolean;
    user?: IUser;
    response: responseType;
    login: loginType;
    logout: () => void;
    reload: () => Promise<any>;
    updateUser: () => Promise<any>;
    setUser: Dispatch<SetStateAction<IUser | undefined>>;
    setAuthString: Dispatch<SetStateAction<string | null>>;
}

const ApiContext = createContext<IApiContext>({} as IApiContext);

export const useApiContext = () => useContext(ApiContext);

export default ApiContext;
