import React from "react";
import styles from "./GradientText.module.scss";

interface IGradientText {
    text: string;
    underlineText?: string;
    small?: boolean;
    className?: string;
}

const GradientText: React.FC<IGradientText> = ({ text, underlineText, className, small }) => {
    return (
        <div className={[styles.wrapper, className].join(" ")}>
            <h3 className={[styles.sairus, small ? styles.small : ""].join(" ")}>{text}</h3>
            {underlineText && <span className={[styles.underlineText, small ? styles.small : ""].join(" ")}>{underlineText}</span>}
        </div>
    );
};

export default GradientText;
