// @ts-ignore
import styles from "./ChangeWallet.module.scss";
import { ChangeWalletProps } from "./ChangeWallet.types";
import Button from "../Button";
import { useApiContext } from "../../contexts/ApiContext";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeWalletNumber, setWallets } from "../../app/reducers/wallets/wallet.slice";
import { setCurrentModal, setModalStep } from "../../app/reducers/modal/modal.slice";
import { ModalsTypesEnum } from "../../app/reducers/modal/modal.types";
import { TextField } from "../UI/TextField";
import { Wallet } from "../Wallets/Wallets";
import { setError } from "../../app/reducers/error/error.slice";

export default function ChangeWallet(props: ChangeWalletProps) {
    const { t } = useTranslation();

    const {
        wallet: { currentWallet: wallet },
        modal: { modalStep: step },
    } = useAppSelector((state) => state);

    const { response } = useApiContext();

    const dispatch = useAppDispatch();

    const linkHandler = () => {
        dispatch(setCurrentModal(ModalsTypesEnum.REMOVE));
    };

    const save = () => {
        const data = JSON.stringify({
            currency: wallet.currency,
            paymentSystem: wallet.paymentSystem,
            wallet: wallet.wallet,
        });

        response("/user/wallet", "POST", data).then((res) => {
            if (res.code === "OK") {
                if (res.result) dispatch(setWallets(res.result));
                else dispatch(setWallets([]));
                dispatch(setModalStep(2));
            } else dispatch(setError(res.error.message));
        });
    };

    return (
        <div className={styles.changeWallet}>
            {step === 1 && (
                <>
                    <Wallet wallet={wallet} />
                    <div className={styles.title}>{t("modals.edit-title", [t(`wallets.${wallet.paymentSystem}`)])}</div>
                    <div className={styles.text}>{t("modals.wallet-address")}</div>
                    <TextField
                        className={styles.textField}
                        placeholder={t("modals.edit-placeholder", [t(`wallets.${wallet.paymentSystem}`)]) || undefined}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(changeWalletNumber(e.target.value))}
                        value={wallet.wallet}
                    />
                    {/* <Input
                        size={20}
                        placeholder={t("modals.edit-placeholder", [t(`wallets.${wallet.paymentSystem}`)]) || undefined}
                        background={"#F5F5F5"}
                        value={wallet.wallet}
                        onChange={(e) => dispatch(changeWalletNumber(e.target.value))}
                    /> */}
                    {/* <div className="text-green" style={{ marginTop: "5px", marginBottom: "20px" }} onClick={() => setEdit(!edit)}>{t('modals.change')}</div> */}
                    <Button label={t("modals.save")} className={styles.button} onClick={save} isNeededStar={false} />
                    <div className={styles.link} onClick={linkHandler}>
                        <i className="icon icon-delete"></i>
                        {t("modals.delete-wallet")}
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <Wallet wallet={wallet} />
                    <div className={styles.title}>{t("modals.edit-sucsses")}</div>
                    <div className={styles.text}>{t("modals.new-wallet-address")}</div>
                    <TextField
                        className={styles.textField}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => null}
                        value={wallet.wallet}
                    />
                </>
            )}
        </div>
    );
}
